import React from 'react';
import { Layout, PageHeader, Seo, ContactDetails } from '../components';
import { graphql } from 'gatsby';

const Contact = ({ data }) => {
   return (
      <Layout>
         <Seo
            title='Contact Us'
            description='Entrust us with your logistics and transportation needs!

'
         />
         <PageHeader
            title='Contact Us'
            description='Entrust us with your logistics and transportation needs!'
            bgImg={data.titleImage.childImageSharp.gatsbyImageData}
         />
         <ContactDetails />
      </Layout>
   );
};

export const query = graphql`
   {
      titleImage: file(name: { eq: "contact-header" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
         }
      }
   }
`;

export default Contact;
